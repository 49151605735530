import { Avatar, Box, ButtonBase, Drawer, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import preferredImageSize from '../../utils/preferred-image-size';
import { stringToColor } from '../../store/constant';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import useAuth from '../../hooks/useAuth';
import { useCallback, useState } from 'react';
import EllipsisTypography from '../../ui-component/optimized-text-fields/EllipsisTypography';
import useChangeCompany from '../../hooks/useChangeCompany';
import CreateOrganizationBtn from './CreateOrganizationBtn';

const SidebarOrganizationSwitcher = () => {
    const { user } = useAuth();
    const { handleChangeCompany } = useChangeCompany();
    const [open, setOpen] = useState(false);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    if (!user) return null;

    return (
        <>
            <ButtonBase sx={{ p: 0.5, flexShrink: 0, flexGrow: 0 }} onClick={() => setOpen(true)}>
                <Avatar
                    color="inherit"
                    src={preferredImageSize(user?.currentCompany.logo, 'small')}
                    sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: user?.currentCompany.logo ? 'transparent' : stringToColor(user.currentCompany.name),
                        color: '#fff'
                    }}
                >
                    <Typography>{user?.currentCompany?.name.charAt(0).toUpperCase()}</Typography>
                </Avatar>
                <ArrowDropDownOutlinedIcon />
            </ButtonBase>

            <Drawer anchor="bottom" open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '20px 20px 0 0' } }}>
                <Stack p={2} spacing={2} sx={{ width: '100vw' }}>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', color: 'grey.800' }}>
                            <IconButton color="inherit" onClick={onClose}>
                                <ClearOutlinedIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '1.5rem', lineHeight: 1.15, color: 'grey.800' }}>
                            Выберите компанию
                        </Typography>
                    </Box>
                    <Box sx={{ py: 1, borderRadius: '4px', backgroundColor: 'grey.50' }}>
                        {user?.companies.map((company) => (
                            <MenuItem
                                key={company.id}
                                value={company.id}
                                sx={{ px: 1.5, py: 2 }}
                                onClick={() => handleChangeCompany(company.id)}
                            >
                                <Stack direction="row" alignItems="center">
                                    <Avatar
                                        color="inherit"
                                        src={preferredImageSize(company.logo, 'small')}
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            backgroundColor: company.logo ? 'transparent' : stringToColor(company.name),
                                            color: '#fff'
                                        }}
                                    >
                                        <Typography>{company?.name.charAt(0).toUpperCase()}</Typography>
                                    </Avatar>
                                    <EllipsisTypography
                                        maxWidth={500}
                                        text={company.name}
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '1rem',
                                            lineHeight: '1.5rem',
                                            color: 'grey.800'
                                        }}
                                    />
                                </Stack>
                            </MenuItem>
                        ))}
                    </Box>

                    <CreateOrganizationBtn isInSideBar actionCb={onClose} />
                </Stack>
            </Drawer>
        </>
    );
};

export default SidebarOrganizationSwitcher;

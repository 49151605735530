import { ReactNode, RefObject, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme, useMediaQuery, Box, IconButton } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
import UnfilledButton from '../form/buttons/UnfilledButton';
import FilledButton from '../form/buttons/FilledButton';
import CloseSvg from '../wizard/svgs/CloseSvg';
import { SxProps } from '@material-ui/system';
import { ModalProps } from '@mui/material';

interface Colors {
    color: string;
    bgColor: string;
    contentBgColor: string;
    footerBgColor: string;
}

const StyledDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== 'matchSm' && prop !== 'colors' && prop !== 'headerColor' && prop !== 'type'
})<{
    matchSm: boolean;
    colors: Colors;
    headerColor?: string;
    type?: 'notification' | 'dialog';
}>(({ theme, matchSm, colors, headerColor, type }) => {
    let dialogActionsPadding;

    switch (type) {
        case 'notification':
            dialogActionsPadding = '0px 20px 24px 20px';
            break;
        default:
            dialogActionsPadding = matchSm ? theme.spacing(1, 2) : theme.spacing(2, 3);
            break;
    }

    return {
        '& .MuiDialog-paper': {
            position: 'relative',
            padding: 0,
            borderRadius: '20px',

            '@media (max-width: 600px)': {
                borderRadius: type === 'notification' ? '20px' : '0'
            }
        },

        '& .MuiDialogTitle-root': {
            backgroundColor: headerColor || colors.bgColor,
            color: colors.color,
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '1.3rem',
            textAlign: 'center',
            padding: matchSm ? theme.spacing(2, 1) : '14px 24px'
        },

        '& .MuiDialogContent-root': {
            padding: type === 'notification' ? '44px 55px !important' : `20px !important`,
            backgroundColor: colors.contentBgColor,
            fontSize: type === 'notification' ? '18px' : ``,
            fontWeight: type === 'notification' ? '400' : ``,
            color: type === 'notification' ? `${theme.palette.newColors?.black}` : ``,
            textAlign: type === 'notification' ? `center` : ``,

            '@media(max-width: 700px)': {
                padding: type === 'notification' ? '32px 57px !important' : `16px !important`,
                height: '100%'
            }
        },

        '& .MuiDialogActions-root': {
            background: colors.footerBgColor,
            padding: dialogActionsPadding,
            justifyContent: type === 'notification' ? 'center' : 'end',

            '&:empty': {
                display: 'none'
            }
        },
        '& .ok-btn': {
            color: colors.color,
            background: colors.bgColor,

            '&:hover': {
                color: colors.color,
                background: colors.bgColor
            }
        },
        '& .cancel-btn': {
            color: colors.bgColor,
            borderColor: colors.bgColor,

            '&:hover': {
                color: colors.bgColor,
                borderColor: colors.bgColor
            }
        }
    };
});

interface CBModalProps {
    id?: string;
    open: boolean;
    onClose: () => void;
    title?: ReactNode | string;
    children?: ReactNode | string | null;
    closeButtonText?: string;
    fullWidth?: boolean;
    fullScreen?: boolean;
    maxWidth?: 'xl' | 'md' | 'mobile' | 'sm' | 'xs' | 'lg' | false;
    okButtonText?: string;
    onClickOk?: () => void;
    okButtonDisabled?: boolean;
    specialContent?: ReactNode | string;
    okButtonStartIcon?: ReactNode;
    okButtonFormId?: string;
    contentRef?: any;
    hideCloseButton?: boolean;
    severity?: 'error' | 'warning' | 'success' | 'info';
    headerColor?: string;
    type?: 'notification' | 'dialog';
    overrideColors?: Partial<Colors>;
    styleOverrides?: SxProps<Theme>;
    submitButtonRef?: RefObject<HTMLButtonElement>;
    container?: ModalProps['container'];
}

const CBModal = ({
    id,
    open,
    onClose,
    title,
    children,
    closeButtonText = 'Закрыть',
    fullWidth,
    fullScreen,
    maxWidth,
    okButtonText,
    onClickOk,
    okButtonDisabled = false,
    okButtonStartIcon,
    okButtonFormId,
    specialContent,
    contentRef,
    hideCloseButton,
    severity = 'info',
    headerColor,
    type = 'dialog',
    overrideColors = {},
    styleOverrides,
    submitButtonRef,
    container
}: CBModalProps) => {
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const theme = useTheme();

    const colors: Colors = useMemo(() => {
        switch (severity) {
            case 'warning':
                return {
                    color: theme.palette.grey[900],
                    bgColor: theme.palette.warning.dark,
                    contentBgColor: '#fff',
                    footerBgColor: theme.palette.grey[200]
                };
            case 'error':
                return {
                    color: '#fff',
                    bgColor: theme.palette.error.main,
                    contentBgColor: '#fff',
                    footerBgColor: theme.palette.grey[200]
                };
            case 'success':
                return {
                    color: theme.palette.grey[900],
                    bgColor: theme.palette.success.main,
                    contentBgColor: '#fff',
                    footerBgColor: theme.palette.grey[200]
                };
            default:
                return {
                    color: '#fff',
                    bgColor: '#1889B8',
                    contentBgColor: '#fefefa',
                    footerBgColor: '#fefefa',
                    ...overrideColors
                };
        }
    }, [overrideColors, severity, theme]);
    return open ? (
        <StyledDialog
            id={id}
            open={open}
            onClose={onClose}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            matchSm={matchSm}
            fullScreen={fullScreen}
            colors={colors}
            headerColor={headerColor}
            type={type}
            sx={styleOverrides}
            container={container}
            disableEnforceFocus={!!container}
        >
            {title && (
                <DialogTitle>
                    {title}
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: '20px',
                            top: '14px',
                            cursor: 'pointer',
                            padding: '0',
                            '&:hover': {
                                background: 'transparent'
                            }
                        }}
                    >
                        <CloseSvg />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent ref={contentRef}>{children}</DialogContent>
            <DialogActions>
                {specialContent && (
                    <Box mr="auto" pr={1} className="DialogSpecialContent">
                        {specialContent}
                    </Box>
                )}
                {!hideCloseButton && (
                    <UnfilledButton
                        onClick={onClose}
                        text={closeButtonText}
                        size={type === 'notification' ? 'lg' : 'md'}
                        className="DialogCancelBtn"
                    />
                )}
                {okButtonText && (onClickOk || okButtonFormId) && (
                    <FilledButton
                        type={onClickOk ? 'button' : 'submit'}
                        size={type === 'notification' ? 'lg' : 'md'}
                        onClick={onClickOk || undefined}
                        disabled={okButtonDisabled}
                        startIcon={okButtonStartIcon}
                        form={okButtonFormId}
                        id={id}
                        text={okButtonText}
                        className="DialogOkBtn"
                        buttonRef={submitButtonRef}
                    />
                )}
            </DialogActions>
        </StyledDialog>
    ) : null;
};

export default CBModal;

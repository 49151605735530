import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeConfirmPopup } from '../../store/confirmPopupSlice';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Drawer,
    IconButton,
    Typography,
    useMediaQuery,
    Theme
} from '@mui/material';
import StickyFormFooter from '../form/sticky-form-footer';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import UpdatedStyleWrapper from '../updated-style-wrapper';
import { useCallback, useMemo } from 'react';
import { SuccessIcon, WarningIcon } from './modal-icons';

const ConfirmPopup = () => {
    const {
        confirmData: {
            onClose,
            onConfirm,
            confirmText,
            cancelText,
            text,
            isConfirmDisabled,
            id,
            title,
            bottomPosition,
            hideCancelButton,
            confirmType = 'warning'
        },
        isOpened
    } = useAppSelector((state) => state.confirmPopup);
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));

    const bottomPlacement = useMemo(() => isMobile || !!bottomPosition, [isMobile, bottomPosition]);

    const closeFunc = useCallback(() => {
        if (onClose) {
            onClose();
        }
        dispatch(closeConfirmPopup());
    }, [dispatch, onClose]);

    const handleConfirm = useCallback(() => {
        if (onConfirm) {
            onConfirm();
        }
        dispatch(closeConfirmPopup());
    }, [dispatch, onConfirm]);

    const renderActions = useCallback(
        () => (
            <>
                {!hideCancelButton ? (
                    <Button color="primary" variant="text" className="forcedBg" onClick={closeFunc} disableElevation>
                        {cancelText ?? 'Отмена'}
                    </Button>
                ) : null}

                <Button disabled={isConfirmDisabled} color="primary" variant="contained" onClick={handleConfirm} disableElevation>
                    {confirmText ?? 'OK'}
                </Button>
            </>
        ),
        [cancelText, closeFunc, confirmText, handleConfirm, hideCancelButton, isConfirmDisabled]
    );

    const renderIcon = useCallback(() => {
        if (!confirmType) {
            return null;
        }

        return (
            <Box mb={1}>
                {confirmType === 'success' ? <SuccessIcon /> : null}
                {confirmType === 'warning' ? <WarningIcon /> : null}
            </Box>
        );
    }, [confirmType]);

    if (isOpened && bottomPlacement) {
        return (
            <UpdatedStyleWrapper>
                <Drawer open onClose={closeFunc} anchor="bottom" PaperProps={{ sx: { borderRadius: '20px 20px 0 0' } }}>
                    <Box p={2} sx={{ textAlign: 'center' }}>
                        <Box sx={{ color: 'grey.800', textAlign: 'right' }}>
                            <IconButton onClick={closeFunc} color="inherit">
                                <ClearOutlinedIcon />
                            </IconButton>
                        </Box>
                        {renderIcon()}
                        {title && (
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '1.5rem',
                                    lineHeight: '1.15',
                                    mb: 1
                                }}
                            >
                                {title}
                            </Typography>
                        )}
                        {text ? (
                            <Box mb={1} sx={{ fontSize: '1rem', lineHeight: 1.5 }}>
                                {text}
                            </Box>
                        ) : null}
                        <StickyFormFooter>{renderActions()}</StickyFormFooter>
                    </Box>
                </Drawer>
            </UpdatedStyleWrapper>
        );
    }

    return isOpened ? (
        <UpdatedStyleWrapper>
            <Dialog
                fullWidth
                maxWidth="sm"
                id={id}
                onClose={closeFunc}
                open={isOpened}
                PaperProps={{ sx: { borderRadius: '16px', paddingY: 2, paddingX: 1 } }}
            >
                <DialogTitle sx={{ padding: 0, display: 'flex', justifyContent: 'flex-end', color: 'grey.800' }}>
                    <IconButton onClick={closeFunc} color="inherit" size="small">
                        <ClearOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ textAlign: 'center', fontSize: '1rem', lineHeight: 1.5, paddingBottom: 2 }}>
                    {renderIcon()}
                    {title && (
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 600,
                                fontSize: '2rem',
                                lineHeight: '1.25',
                                color: 'primary.900',
                                mb: 1
                            }}
                        >
                            {title}
                        </Typography>
                    )}
                    {text ? <Box sx={{ color: 'grey.800', opacity: 0.6 }}>{text}</Box> : null}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>{renderActions()}</DialogActions>
            </Dialog>
        </UpdatedStyleWrapper>
    ) : null;
};

export default ConfirmPopup;

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useAuth from '../../../hooks/useAuth';
import { useCallback, useEffect, useMemo } from 'react';
import { EmployeeCalendarSettings, UserRole } from '../../../models/IEmployee';
import { ILocation } from '../../../models/ILocation';
import useEmployeeOptions from '../../../hooks/options/useEmployeeOptions';
import { setLocalFilterSettings } from '../../../store/slices/calendarFilterSlice';

const APP_SETTINGS_PREFIX = 'ZPRO_FILTER_SETTINGS';

const useLocalCalendarFilters = () => {
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const userId = user?.id;
    const userEmployeeId = user?.employee?.id;
    const userCompanyId = user?.currentCompany?.id;
    const isProvider = user?.employee?.role.name === UserRole.Provider;
    const { employees } = useEmployeeOptions('true');
    const { employeeSettings, selectedLocation } = useAppSelector((state) => state.calendarFilter);
    const selectedLocationId = selectedLocation?.id;
    const settingsKey = useMemo(() => {
        if (userId && userCompanyId) {
            return `${APP_SETTINGS_PREFIX}_${userCompanyId}_${userId}`;
        }

        return null;
    }, [userCompanyId, userId]);

    const hasInvalidProviderEmployeeSettings = useMemo(() => {
        const locations = employeeSettings?.locations ?? [];
        const hasCurrentSettings = locations.some(({ id }) => id === selectedLocationId);
        if (isProvider && hasCurrentSettings && userEmployeeId) {
            return locations.some((loc) => !(loc.employees.includes(userEmployeeId) && loc.employees.length === 1));
        }

        return false;
    }, [employeeSettings, isProvider, selectedLocationId, userEmployeeId]);

    const createDefaultLocationSettings = useCallback(
        (processedLocation: ILocation) => ({
            id: processedLocation.id,
            services: processedLocation.services.map(({ id }) => id),
            employees:
                userEmployeeId && isProvider
                    ? [userEmployeeId]
                    : employees.filter((employee) => employee.locations.some(({ id }) => id === processedLocation.id)).map(({ id }) => id)
        }),
        [employees, isProvider, userEmployeeId]
    );

    const createDefaultSettings = useCallback(
        () => ({
            show_scheduled_staff: false,
            show_canceled_appointments: true,
            selected_location_id: selectedLocationId,
            locations: [],
            show_archived_staff: false
        }),
        [selectedLocationId]
    );

    const getStorageSettings = useCallback(() => {
        if (settingsKey) {
            const stored = window.localStorage.getItem(settingsKey);
            return stored ? JSON.parse(stored) : createDefaultSettings();
        }

        return null;
    }, [createDefaultSettings, settingsKey]);

    const saveLocalSettings = useCallback(
        (data: EmployeeCalendarSettings) => {
            if (settingsKey) {
                dispatch(setLocalFilterSettings(data));
                window.localStorage.setItem(settingsKey, JSON.stringify(data));
            }
        },
        [dispatch, settingsKey]
    );

    useEffect(() => {
        if (!employeeSettings && settingsKey) {
            const settings = getStorageSettings();
            dispatch(setLocalFilterSettings(settings));
        }
    }, [dispatch, employeeSettings, getStorageSettings, settingsKey]);

    useEffect(() => {
        const locations = employeeSettings?.locations ?? [];
        const hasCurrentSettings = locations.some(({ id }) => id === selectedLocationId);

        if (selectedLocation && selectedLocationId && employeeSettings && (isProvider || !!employees.length)) {
            if (!hasCurrentSettings) {
                const newSettings = {
                    ...employeeSettings,
                    locations: [...locations, createDefaultLocationSettings(selectedLocation)]
                };

                saveLocalSettings(newSettings);
            }
        }
    }, [
        createDefaultLocationSettings,
        employeeSettings,
        employees.length,
        isProvider,
        saveLocalSettings,
        selectedLocation,
        selectedLocationId
    ]);

    // If current user is Provider and have som incompatible employee filtering settings this effect will fix it
    useEffect(() => {
        const locations = employeeSettings?.locations ?? [];
        const hasCurrentSettings = locations.some(({ id }) => id === selectedLocationId);

        if (hasCurrentSettings && userEmployeeId && hasInvalidProviderEmployeeSettings && employeeSettings) {
            const newSettings = {
                ...employeeSettings,
                locations: employeeSettings?.locations?.map((loc) => ({ ...loc, employees: [userEmployeeId] })) ?? []
            };

            saveLocalSettings(newSettings);
        }
    }, [employeeSettings, hasInvalidProviderEmployeeSettings, saveLocalSettings, selectedLocationId, userEmployeeId]);

    return { calendarSettings: employeeSettings, updateCalendarSettings: saveLocalSettings };
};

export default useLocalCalendarFilters;

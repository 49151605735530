import { useMemo, useState } from 'react';
import SectionHeading from '../../../views/calendar/appointment-card/appointment-details/elements/SectionHeading';
import { IEmployee } from '../../../models/IEmployee';
import { Autocomplete, TextField } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import UserAvatar from '../../UserAvatar';
import { FormFieldProps } from '../index';
import { useForceMobileLayoutContext } from '../../force-mobile-layout-context';
import useEmployeeOptions from '../../../hooks/options/useEmployeeOptions';

type ProviderSelectProps = Omit<FormFieldProps, 'setFieldValue' | 'setFieldTouched'> & {
    onChange: (v: IEmployee | null) => void;
};

const ProviderSelect = ({ values, onChange, touched, errors, handleBlur, disabled }: ProviderSelectProps) => {
    const { employees } = useEmployeeOptions('false');
    const [inputVal, setInputVal] = useState('');
    const forceMobile = useForceMobileLayoutContext();
    const provider = useMemo(() => values?.employee || null, [values]);

    return (
        <>
            <SectionHeading mb={1} mt={1}>
                Сотрудник
            </SectionHeading>
            <Grid container item xs={12} sm={forceMobile ? 12 : 6}>
                <Autocomplete
                    id="employee"
                    fullWidth
                    value={provider}
                    disabled={!employees?.length || disabled}
                    options={employees || []}
                    getOptionLabel={(option: IEmployee) => option.title}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    noOptionsText="Ничего не найдено"
                    inputValue={inputVal}
                    onInputChange={(event, newInputValue) => {
                        setInputVal(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={!provider && !inputVal ? 'Выберите сотрудника' : ''}
                            InputLabelProps={{
                                shrink: false
                            }}
                            error={Boolean(touched?.employee && errors?.employee)}
                            helperText={touched?.employee ? errors?.employee : null}
                        />
                    )}
                    onChange={(e, newValue) => {
                        onChange(newValue);
                    }}
                    onBlur={handleBlur}
                    renderOption={(props, option: IEmployee) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <UserAvatar employee={option} sx={{ width: '30px', height: '30px', mr: 1 }} />
                            {option.title}
                        </Box>
                    )}
                    disableClearable
                />
            </Grid>
        </>
    );
};

export default ProviderSelect;

import { colors } from '../store/constant';
import { Avatar, Typography } from '@material-ui/core';
import { IEmployee } from '../models/IEmployee';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@material-ui/system';
import preferredImageSize from '../utils/preferred-image-size';

interface UserAvatarProps {
    employee: IEmployee;
    sx?: SxProps;
}

const UserAvatar = ({ employee, sx }: UserAvatarProps) => {
    const theme = useTheme();

    return (
        <Avatar
            src={preferredImageSize(employee?.avatar ?? employee?.user?.avatar ?? null, 'small')}
            sx={{
                backgroundColor: employee?.background_color ? `#${employee?.background_color}` : colors.blue.value,
                color: theme.palette.getContrastText(employee?.background_color ? `#${employee?.background_color}` : colors.blue.value),
                width: 40,
                height: 40,
                ...sx
            }}
        >
            <Typography fontSize="large" sx={{ color: 'inherit' }}>
                {employee?.title.charAt(0).toUpperCase()}
            </Typography>
        </Avatar>
    );
};

export default UserAvatar;

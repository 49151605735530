import { useAppDispatch } from '../../hooks/redux';
import useSaveAppointment from '../../views/calendar/appointment-card/appointment-details/hooks/useSaveAppointment';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import useAttachmentFunctions from '../../hooks/useAttachmentFunctions';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { uploadImagesAndSubmit } from '../../utils/functions/uploading-images-helpers';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import { Box, FormControlLabel, FormHelperText, Grid, Link, Switch, TextField } from '@mui/material';
import CustomerSelect from './elements/customer-select';
import StatusSelect from './elements/status-select';
import ProviderSelect from './elements/ProviderSelect';
import DateTime from './elements/DateTime';
import Attachments from './elements/Attachments';
import { IEmployee, UserRole } from '../../models/IEmployee';
import { ILocation } from '../../models/ILocation';
import { IService, PaymentType, ServiceAnswerType } from '../../models/IService';
import { ICustomer, ICustomerPayload } from '../../models/ICustomer';
import { IPayment } from '../../models/IPayment';
import { AppointmentStatuses, AppointmentType, IAppointment, IDepositRequestPayload } from '../../models/IAppointment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageData } from '../../models/IImage';
import { Fade, Theme, useMediaQuery } from '@material-ui/core';
import useDialogFunctions from '../../hooks/useDialogFunctions';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import useShouldSubmitDetailsForm from '../../hooks/useShouldSubmitDetailsForm';
import useAppointmentDetailsFormFunctions from '../../hooks/useAppointmentDetailsFormFunctions';
import useAuth from '../../hooks/useAuth';
import DepositRequestForm, { depositRequestSchema } from '../form/deposit-request/DepositRequestForm';
import SectionHeading from '../../views/calendar/appointment-card/appointment-details/elements/SectionHeading';
import { CancellationReason } from '../../views/calendar/types';
import { cancellationReasons } from '../../store/constant';
import DateAutocomplete from '../DateAutocomplete';
import useFreeDateInput from '../../hooks/useFreeDateInput';
import useAppointmentEditRestrictions from '../../hooks/useAppointmentEditRestrictions';
import AppointmentServicesSubform, { AppointmentServiceRowType } from './elements/appointment-services-subform';
import { getMaterialsBasedServicePrice, getServiceArrayDuration, isAllServiceExternal, isAllServiceVirtual } from '../../utils/services';
import servicesSchema from '../form/schemes/services-array-schema';
import compareServices from './utils/compare-services';
import { useForceMobileLayoutContext } from '../force-mobile-layout-context';
import useDuplicateCustomerConfirm from '../../hooks/use-duplicate-customer-confirm';
import AppointmentAddressBox from '../AppointmentAddressBox';
import { IAddress } from '../../models/IAddress';
import AppointmentNotes from './elements/AppointmentNotes';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import ConditionalDialog from '../conditional-dialog/ConditionalDialog';
import ExistingCustomerAlert from '../ExistingCustomerAlert';
import useCreateCustomer from '../../hooks/use-create-customer';
import usePlanName from '../../hooks/usePlanName';

export interface FormFieldProps {
    values: FormikValues;
    setFieldValue: (fieldName: string, value: any) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    setFieldTouched: (a: string) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    disabled?: boolean;
}

interface initialDataType {
    data: {
        id?: number;
        status: AppointmentStatuses;
        employee: IEmployee | null;
        location: ILocation | null;
        services: IService[] | null;
        customer: ICustomer | null;
        start_at: IAppointment['start_at'] | null;
        end_at: IAppointment['end_at'] | null;
        type: AppointmentType;
        note?: string;
        private_note?: string;
        images: ImageData[];
        payments: IPayment[] | null;
        is_notifications_enabled?: boolean;
        cancel_reason?: CancellationReason;
        deposit_request?: IDepositRequestPayload | null;
        remind_after_interval?: string | null;
        remind_at?: string | null;
        has_waiver?: IAppointment['has_waiver'];
        cbvc_url?: IAppointment['cbvc_url'];
        address?: IAppointment['address'];
    };
    callback?: () => void;
    isDepositRequested?: boolean;
    handleCancelDepositRequest?: () => void;
    isNew?: boolean;
    onStartDateChange?: (date: Date) => void;
    isReadOnly?: boolean;
}

const getInitialServices = (services: IService[] | null): AppointmentServiceRowType[] => {
    if (services && services.length) {
        return services?.map((service) => ({
            service,
            price: service.pivot?.price || null,
            prepay: service.pivot?.prepay || null,
            materials_amount: service.pivot?.materials?.amount || null
        }));
    }

    return [{ service: undefined, price: null, prepay: null, materials_amount: null }];
};

const AppointmentForm = ({
    data,
    callback,
    isDepositRequested,
    handleCancelDepositRequest,
    isNew,
    onStartDateChange,
    isReadOnly = false
}: initialDataType) => {
    const [pendingData, setPendingData] = useState(false);
    // const [createCustomer] = customerAPI.useCreateCustomerMutation();
    // const [updateCustomer] = customerAPI.useUpdateCustomerMutation();
    const [dialog, setDialog] = useState(false);
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const { saveAppointment, isSavingAppointment } = useSaveAppointment();
    const { showSnackbar } = useShowSnackbar();

    const planName = usePlanName();

    const { data: customerErrorData, handleCustomerError, existingCustomer, clearError } = useDuplicateCustomerConfirm();

    const { isDialogOpen: isNewCustomerOpen, openDialog: openNewCustomer, closeDialog: closeNewCustomer } = useDialogFunctions();

    const handleCloseNewCustomer = useCallback(() => {
        clearError();
        closeNewCustomer();
        setPendingData(false);
    }, [clearError, closeNewCustomer]);

    const forceMobile = useForceMobileLayoutContext();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm')) || forceMobile;

    const newCustomerFormContainer = useRef<any>(null);

    const endAt = useMemo(() => (data?.end_at ? moment(data.end_at).add(1, 'second').toISOString() : null), [data]);

    const calculateDuration = useCallback((startDate?: string | null, endDate?: string | null) => {
        if (startDate && endDate) {
            const diff = parseFloat(moment.duration(moment(endDate).diff(startDate)).asMinutes().toFixed(0));
            return diff < 1 ? 0 : diff;
        }

        return 0;
    }, []);

    const initialValues = useMemo(
        () => ({
            ...data,
            services: getInitialServices(data.services),
            start_at: moment(data.start_at).toISOString(),
            duration: data ? calculateDuration(data.start_at, endAt) : 0,
            end_at: endAt,
            use_reminder: Boolean(data.remind_at && data.remind_after_interval),
            remind_after_interval: data.remind_after_interval ?? ''
        }),
        [data, endAt, calculateDuration]
    );

    const {
        attachments,
        setAttachments,
        attachmentsIdsToDelete,
        setAttachmentsIdsToDelete,
        attachmentError,
        setAttachmentError
    } = useAttachmentFunctions(initialValues.images);

    const isProvider = useMemo(() => user?.employee.role.name === UserRole.Provider, [user]);

    const submitBtnRef = useRef<HTMLButtonElement>(null);

    const onSubmit = (formData: FormikValues) => {
        if (!data || isSavingAppointment) return;
        const timezone = formData?.location?.time_zone || 'utc';
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { address, ...restFormData } = formData;
        const payload = isExternalAppointment ? formData : restFormData;
        if (!formData?.id && moment.tz(formData.start_at, timezone) <= moment().tz(timezone)) {
            dispatch(
                openConfirmPopup({
                    onConfirm: () => {
                        handleSaveAppointment(payload);
                    },
                    onClose: () => {
                        dispatch(stopSubmitting());
                    },
                    title: 'Запись на прошедшую дату',
                    confirmText: 'Создать',
                    text: `Указанная дата уже прошла. Вы уверены что хотите создать запись на прошедшую дату?`
                })
            );
        } else {
            handleSaveAppointment(payload);
        }
    };

    const {
        touched,
        setTouched,
        errors,
        setErrors,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        values,
        handleSubmit,
        validateForm,
        setFieldError,
        submitCount
    } = useExtendedFormik({
        enableReinitialize: true,
        initialValues,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: Yup.object().shape({
            customer: isNewCustomerOpen
                ? Yup.mixed().nullable().notRequired()
                : Yup.object().required('Добавьте клиента').typeError('Добавьте клиента'),
            employee: Yup.object().required('Добавьте сотрудника').typeError('Добавьте сотрудника'),
            services: Yup.array().of(servicesSchema).min(1, 'Добавьте услугу').required('Добавьте услугу'),
            note: Yup.string().nullable(),
            private_note: Yup.string().nullable(),
            start_at: Yup.string().required('Укажите дату начала').typeError('Неверная дата начала'),
            duration: Yup.number()
                .min(10, 'Минимально допустимая длительность - 10 минут')
                .max(600, 'Максимально допустимая длительность 10 часов (600 минут)'),
            is_notifications_enabled: Yup.boolean(),
            deposit_request: depositRequestSchema,
            remind_after_interval: Yup.mixed().when('use_reminder', {
                is: true,
                then: Yup.string()
                    .trim()
                    .required('Укажите интервал напоминания')
                    .test({
                        name: 'remind_after_interval',
                        test: () => !!suggestion,
                        message: 'Не удается распознать дату'
                    }),
                otherwise: Yup.string().nullable().notRequired()
            })
            /* address: Yup.mixed().when('services', {
                is: (servicesValue: Array<AppointmentServiceRowType>) =>
                    servicesValue.some((service) => service?.service?.location_type === ServiceLocationType.External),
                then: Yup.object()
                    .shape({
                        address: Yup.string().trim().required().label('Адрес выезда')
                    })
                    .typeError('Укажите адрес выезда')
                    .required()
                    .label('Адрес выезда'),
                otherwise: Yup.mixed().nullable().notRequired()
            }) */
        }),
        onSubmit,
        isBlocked: !data || isSavingAppointment
    });

    const selectedServices = useMemo<IService[]>(
        () => values.services.reduce<IService[]>((acc, current) => (current.service ? [...acc, current.service] : acc), []),
        [values.services]
    );

    const isVirtualAppointment = useMemo(() => isAllServiceVirtual(data.services || []), [data.services]);
    const isExternalAppointment = useMemo(() => isAllServiceExternal(selectedServices), [selectedServices]);

    const useRebookReminder = useMemo(() => values.services.some(({ service }) => !!service?.use_rebook_reminder), [values.services]);

    const { canEditKeyFields } = useAppointmentEditRestrictions();

    const toggleRebookReminder = useCallback(
        (value: boolean) => {
            setFieldValue('use_reminder', value);
            setFieldValue('remind_after_interval', value ? '1 неделя' : '');
        },
        [setFieldValue]
    );

    const updateRemindAt = useCallback(
        (value: string | null) => {
            setFieldValue('remind_after_interval', value);
        },
        [setFieldValue]
    );

    const { suggestion } = useFreeDateInput(values.remind_after_interval ?? '');
    const isMultiServicesEnabled = !!user?.currentCompany.settings?.widget?.use_multiservices;

    const handleDepositCancel = useCallback(() => {
        setDialog(false);
        if (handleCancelDepositRequest) {
            handleCancelDepositRequest();
            setFieldValue('deposit_request', null);
        }
    }, [handleCancelDepositRequest, setFieldValue]);

    const handleDepositRequest = useCallback(
        (depositData: IDepositRequestPayload) => {
            setFieldValue('deposit_request', depositData);
            handleCancelDepositRequest?.();
            setDialog(false);
            handleSubmit();
        },
        [handleCancelDepositRequest, handleSubmit, setFieldValue]
    );

    const handleSave = useCallback(
        (formData) => {
            dispatch(startSubmitting());
            setPendingData(false);
            uploadImagesAndSubmit({
                attachments,
                submitCb: (urls) => {
                    setAttachmentsIdsToDelete?.([]);

                    const payload = {
                        ...formData,
                        service_ids: formData.services.map((item: AppointmentServiceRowType & { service: IService }) => ({
                            id: item.service.id,
                            price: item.price,
                            prepay: item.prepay,
                            materials_amount: item.service.use_materials ? item.materials_amount : undefined
                        })),
                        images: urls,
                        payments: data.payments,
                        type: data.type,
                        customer_id: formData?.customer?.id || 0,
                        employee_id: formData?.employee?.id || 0,
                        location_id: formData?.location?.id || 0,
                        start_at: formData?.start_at || '',
                        end_at: formData?.end_at ? moment(formData.end_at).subtract(1, 'second').toISOString() : '',
                        deposit_request: formData.deposit_request ? formData.deposit_request : undefined,
                        remind_after_interval: formData.use_reminder ? formData.remind_after_interval : null,
                        remind_at: formData.use_reminder ? formData.remind_at : null
                    };

                    saveAppointment(
                        payload,
                        () => {
                            showSnackbar({
                                message: 'Запись успешно сохранена',
                                alertSeverity: SnackBarTypes.Success
                            });
                            setTouched({}, false);

                            if (callback) callback();
                        },
                        data.id ? data.id.toString() : undefined,
                        () => {
                            handleDepositCancel();
                            dispatch(stopSubmitting);
                        },
                        (e) => {
                            if (e && typeof e === 'object' && 'errors' in e) {
                                Object.entries(e.errors).forEach(([key, value]) => {
                                    const errKey = key.replace('service_ids', 'services');
                                    const errValue = typeof value === 'string' ? value : value.join(', ');

                                    setFieldError(errKey, errValue);
                                });
                            }
                        }
                    );
                },

                updateAttachmentsCb: (files) => setAttachments(files),
                imagesToDelete: attachmentsIdsToDelete,
                uploadingErrorCb: (e) => {
                    setAttachmentsIdsToDelete?.([]);
                    showSnackbar({
                        message: e.message,
                        alertSeverity: SnackBarTypes.Error
                    });
                    dispatch(stopSubmitting());
                },
                deletingImagesErrorCb: (e) => {
                    showSnackbar({
                        message: e.message,
                        alertSeverity: SnackBarTypes.Error
                    });
                    dispatch(stopSubmitting());
                }
            });
        },
        [
            attachments,
            attachmentsIdsToDelete,
            callback,
            data.id,
            data.payments,
            data.type,
            dispatch,
            handleDepositCancel,
            saveAppointment,
            setAttachments,
            setAttachmentsIdsToDelete,
            setFieldError,
            setTouched,
            showSnackbar
        ]
    );

    const handleSaveAppointment = useCallback(
        (formData) => {
            const needSaveCustomerFirst = !formData.customer && isNewCustomerOpen;

            if (needSaveCustomerFirst) {
                setPendingData(true);
                submitBtnRef?.current?.click();
            } else {
                handleSave(formData);
            }
        },
        [handleSave, isNewCustomerOpen, submitBtnRef]
    );

    const handleCustomerCreationCb = useCallback(
        (customer: ICustomer) => {
            setFieldValue('customer', customer);
            setFieldTouched('customer', true, false);
            handleCloseNewCustomer();

            if (pendingData) {
                setPendingData(false);
                handleSaveAppointment({ ...values, customer });
            } else {
                dispatch(stopSubmitting());
                showSnackbar({
                    message: 'Клиент успешно обновлен',
                    alertSeverity: SnackBarTypes.Success
                });
            }
        },
        [setFieldValue, setFieldTouched, handleCloseNewCustomer, pendingData, values, dispatch, showSnackbar, handleSaveAppointment]
    );

    const handleCreateCustomer = useCreateCustomer(handleCustomerCreationCb, handleCustomerError);

    const handleSaveCustomer = useCallback(
        (formData: ICustomerPayload, id?: number) => {
            handleCreateCustomer({ ...formData, id });
        },
        [handleCreateCustomer]
    );

    const appointmentAnswers = useMemo(() => {
        const arr: IService[] = data.services ?? [];
        return arr.reduce<ServiceAnswerType[]>((acc, current) => {
            const answers = current.pivot?.widget_answers;
            if (answers && answers.length > 0) {
                return [...acc, ...answers];
            }

            return acc;
        }, []);
    }, [data.services]);

    const hasTouchedFields = !!Object.values(touched).length;
    const hasChanges = useMemo(() => {
        const hasChangedCustomer = values.customer?.id !== initialValues.customer?.id;
        const dateFields = ['start_at', 'end_at', 'duration'] as const;
        const hasDatesChanged = dateFields.some((field) => values[field] !== initialValues[field]);
        const hasAttachmentsChanged = values.images.length !== attachments.length || !!attachmentsIdsToDelete.length;
        const hasNotificationsChanged = values.is_notifications_enabled !== initialValues.is_notifications_enabled;
        const useReminderChanged = values.use_reminder !== initialValues.use_reminder;
        const reminderIntervalChanged = values.remind_after_interval !== initialValues.remind_after_interval;
        const hasNoteChanged = values.note !== initialValues.note;
        const servicesChanged = compareServices(initialValues.services, values.services);

        return (
            hasTouchedFields ||
            hasChangedCustomer ||
            hasDatesChanged ||
            hasAttachmentsChanged ||
            hasNotificationsChanged ||
            useReminderChanged ||
            reminderIntervalChanged ||
            hasNoteChanged ||
            servicesChanged
        );
    }, [attachments.length, attachmentsIdsToDelete.length, initialValues, hasTouchedFields, values]);

    const combinedDuration = useMemo(() => {
        const servicesArray: IService[] = [];
        values.services.forEach(({ service }) => {
            if (service) servicesArray.push(service);
        });

        return getServiceArrayDuration(servicesArray, values.employee);
    }, [values.employee, values.services]);

    const depositRequestAmount = useMemo(
        () =>
            values.services.reduce<number>((prev, currentValue) => {
                if (currentValue?.service?.payment_type === PaymentType.Prepaid) {
                    return prev + (currentValue?.prepay ?? 0);
                }

                if (currentValue?.service?.payment_type === PaymentType.Paid) {
                    return prev + (currentValue?.price ?? 0);
                }

                return prev;
            }, 0),
        [values.services]
    );

    const handleChangeProvider = useCallback(
        (newValue: IEmployee | null) => {
            setFieldValue('employee', newValue);
            if (newValue) {
                setFieldTouched('employee', false);
            }

            if (isNew) {
                const newServices = values.services.map((serviceRow) => {
                    if (serviceRow.service) {
                        return {
                            ...serviceRow,
                            price: getMaterialsBasedServicePrice(serviceRow.service, newValue?.services || [], serviceRow.materials_amount)
                        };
                    }
                    return serviceRow;
                });
                setFieldValue('services', newServices);
            }
        },
        [isNew, setFieldTouched, setFieldValue, values.services]
    );

    const handleChangeCustomerCb = useCallback(
        (newCustomer: ICustomer) => {
            if (isExternalAppointment) {
                setFieldValue('address', newCustomer.latest_appointment_address || null);
            }
        },
        [isExternalAppointment, setFieldValue]
    );

    /* const onSelectServiceCb = useCallback(
        (service: IService) => {
            if (
                !values.address &&
                !!values.customer?.latest_appointment_address &&
                service?.location_type === ServiceLocationType.External
            ) {
                setFieldValue('address', values.customer.latest_appointment_address ?? '');
            }
        },
        [setFieldValue, values.address, values.customer]
    ); */

    const handleValidation = useCallback(() => {
        validateForm()
            .then((res) => {
                setErrors(res);
                const errorKeys = Object.keys(res);
                if (errorKeys.length) {
                    handleDepositCancel();
                    setDialog(false);
                    errorKeys.forEach((key) => {
                        if (key === 'services') {
                            // Services is array of objects, better set touched fields based on actual value
                            values.services.forEach((s, index) => {
                                for (const field of Object.keys(s)) {
                                    setFieldTouched(`services.${index}.${field}`, true, false);
                                }
                            });
                        } else {
                            setFieldTouched(key, true, false);
                        }
                    });
                } else {
                    setDialog(true);
                }
            })
            .catch((err) => {
                handleDepositCancel();
                console.error(err);
            });
    }, [handleDepositCancel, setErrors, setFieldTouched, validateForm, values.services]);

    const addressError = useMemo(() => {
        if (errors.address && typeof errors.address === 'object') {
            // Because Formik can't recognize correct error type
            // @ts-ignore
            return errors.address.address;
        }

        return errors.address;
    }, [errors.address]);

    useEffect(() => {
        if (!dialog && isDepositRequested) {
            handleValidation();
        }
    }, [dialog, handleValidation, isDepositRequested]);

    useEffect(() => {
        if (values.start_at && onStartDateChange) {
            onStartDateChange(new Date(values.start_at));
        }
    }, [values.start_at, onStartDateChange]);

    useEffect(() => {
        const noDuration = initialValues.start_at === initialValues.end_at;

        if (noDuration) {
            setFieldValue('duration', combinedDuration);
            setFieldValue('end_at', moment(values.start_at).add(combinedDuration, 'minutes').toISOString());
        }
    }, [combinedDuration, initialValues.end_at, initialValues.start_at, setFieldValue, values.start_at]);

    useAppointmentDetailsFormFunctions('appointment-form', isSavingAppointment);
    useShouldSubmitDetailsForm(!isNew ? hasChanges : false, false);

    return (
        <Box px={2}>
            <Fade in={!dialog}>
                <Box sx={dialog ? { position: 'absolute', width: 0, height: 0, opacity: 0 } : undefined}>
                    <Box px={2}>
                        {customerErrorData ? (
                            <ExistingCustomerAlert email={customerErrorData?.email} phone={customerErrorData?.phone} />
                        ) : null}
                    </Box>

                    <Box ref={newCustomerFormContainer} />
                    <form noValidate onSubmit={handleSubmit} id="appointment-form">
                        <Grid container justifyContent="space-between" spacing={matchSm ? 1 : 2}>
                            <Grid container item xs={12} spacing={matchSm ? 2 : 1}>
                                <Grid item xs justifyContent="flex-end">
                                    <CustomerSelect
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        touched={touched}
                                        errors={errors}
                                        setFieldTouched={setFieldTouched}
                                        handleBlur={handleBlur}
                                        formContainerRef={newCustomerFormContainer}
                                        isNewCustomerOpen={isNewCustomerOpen}
                                        closeNewCustomer={handleCloseNewCustomer}
                                        openNewCustomer={openNewCustomer}
                                        disabled={isReadOnly || !canEditKeyFields(values.status)}
                                        onSaveCustomer={handleSaveCustomer}
                                        submitBtnRef={submitBtnRef}
                                        customerSelectCb={handleChangeCustomerCb}
                                        existingCustomer={existingCustomer}
                                    />
                                </Grid>
                                {!!values?.id && !isNewCustomerOpen && (
                                    <Grid container item xs={3} sm={4} alignItems="flex-end">
                                        <StatusSelect
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            disabled={isReadOnly}
                                            useRebookReminder={useRebookReminder}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            {isVirtualAppointment && data.cbvc_url ? (
                                <Grid item xs={12}>
                                    <SectionHeading mb={1}>Ссылка на видеозвонок</SectionHeading>
                                    <Link href={data.cbvc_url} target="_blank">
                                        {data.cbvc_url}
                                    </Link>
                                </Grid>
                            ) : null}

                            {isExternalAppointment ? (
                                <Grid item xs={12} pt={1}>
                                    <AppointmentAddressBox
                                        fullWidth
                                        label="Адрес выезда"
                                        id="address"
                                        name="address"
                                        onChange={(address: IAddress | null) => setFieldValue('address', address)}
                                        onBlur={handleBlur}
                                        value={values.address}
                                        error={(touched.address || !!submitCount) && !!addressError}
                                        helperText={touched.address || submitCount > 0 ? addressError : undefined}
                                    />
                                </Grid>
                            ) : null}

                            {data.status === AppointmentStatuses.Canceled && data.cancel_reason ? (
                                <Grid item xs={12}>
                                    <SectionHeading mb={1}>Причина отмены</SectionHeading>
                                    <TextField disabled value={cancellationReasons[data.cancel_reason].title} />
                                </Grid>
                            ) : null}

                            {values.status === AppointmentStatuses.Completed ? (
                                <>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            sx={{
                                                ml: 0,
                                                width: matchSm ? '100%' : 'auto',
                                                justifyContent: matchSm ? 'space-between' : undefined
                                            }}
                                            control={<Switch checked={values.use_reminder} />}
                                            onChange={(_, checked) => toggleRebookReminder(checked)}
                                            label="Напоминание для следующей записи"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                    {values.use_reminder && (
                                        <Grid item sm={6} xs={12}>
                                            <DateAutocomplete
                                                value={values.remind_after_interval ?? ''}
                                                onChange={updateRemindAt}
                                                suggestion={suggestion}
                                                error={Boolean(errors.remind_after_interval)}
                                                helperText={errors.remind_after_interval}
                                            />
                                        </Grid>
                                    )}
                                </>
                            ) : null}

                            {!isProvider /* && planName !== 'single user' */ ? (
                                <Grid item xs={12}>
                                    <ProviderSelect
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        disabled={isReadOnly || !canEditKeyFields(values.status)}
                                        onChange={handleChangeProvider}
                                    />
                                </Grid>
                            ) : null}

                            <Grid container item xs={12} mb={2}>
                                <AppointmentServicesSubform
                                    value={values.services}
                                    setValue={setFieldValue}
                                    setTouched={setFieldTouched}
                                    valuePrefix="services"
                                    errors={errors.services}
                                    touched={touched.services}
                                    disabled={isReadOnly || !canEditKeyFields(values.status)}
                                    employeeServices={values.employee?.services}
                                    useMultiservices={isMultiServicesEnabled}
                                    // serviceSelectCb={onSelectServiceCb}
                                />
                                {typeof errors.services === 'string' && <FormHelperText error>{errors.services}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12}>
                                <DateTime
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    touched={touched}
                                    errors={errors}
                                    disabled={isReadOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Attachments
                                    values={values}
                                    attachments={attachments}
                                    setAttachments={setAttachments}
                                    attachmentsIdsToDelete={attachmentsIdsToDelete}
                                    setAttachmentsIdsToDelete={setAttachmentsIdsToDelete}
                                    error={attachmentError ?? ''}
                                    setError={setAttachmentError}
                                    disabled={isReadOnly}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <AppointmentNotes
                                    isDisabled={isReadOnly}
                                    formikInstance={{
                                        values,
                                        touched,
                                        errors,
                                        handleBlur,
                                        setFieldValue,
                                        setFieldTouched
                                    }}
                                    showCustomerNotes={!!initialValues.note}
                                    answers={appointmentAnswers}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Fade>
            {isNew && (
                <ConditionalDialog
                    open={dialog}
                    title="Запросить депозит"
                    onCancel={handleDepositCancel}
                    formId="deposit-request-form"
                    okButtonLabel="Запросить"
                >
                    <DepositRequestForm
                        onSubmit={handleDepositRequest}
                        defaults={{
                            expires_at: values.start_at ?? undefined,
                            amount: depositRequestAmount ?? ''
                        }}
                    />
                </ConditionalDialog>
            )}
        </Box>
    );
};

export default AppointmentForm;

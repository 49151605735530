import CBModal from '../../../ui-component/cb-modal/CBModal';
import { AppointmentStatuses, AppointmentType } from '../../../models/IAppointment';
import AppointmentForm from '../../../ui-component/appointment-form';
import { useTheme } from '@material-ui/core/styles';
import { useAppSelector } from '../../../hooks/redux';
import moment from 'moment/moment';
import { ILocation } from '../../../models/ILocation';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useMemo, useState } from 'react';
import { Box, CircularProgress, ModalProps } from '@mui/material';
import customerAPI from '../../../services/CustomerService';
import { skipToken } from '@reduxjs/toolkit/query';
import useEmployeeOptions from '../../../hooks/options/useEmployeeOptions';

interface NewAppointmentModalProps {
    isOpen: boolean;
    onClose: () => void;
    location: ILocation;
    employeeId: number | null;
    startDate?: string | Date;
    endDate?: string | Date;
    container?: ModalProps['container'];
    customerId?: number | null;
}

const NewAppointmentModal = ({
    isOpen,
    onClose,
    location,
    employeeId,
    startDate,
    endDate,
    container,
    customerId
}: NewAppointmentModalProps) => {
    const [depositRequest, setDepositRequest] = useState(false);
    // const { allEmployees } = useAppSelector((state) => state.calendarFilter);
    const { employees } = useEmployeeOptions('true');

    const { data: customerData, isLoading: customerLoading } = customerAPI.useGetCustomerQuery(customerId ? String(customerId) : skipToken);
    const theme = useTheme();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const { appointmentDetailsFormId, appointmentDetailsSaveButtonDisabled } = useAppSelector((state) => state.calendar);

    const { start, end } = useMemo(
        () => ({
            start: moment(startDate).toISOString(),
            end: moment(endDate ?? startDate)
                .subtract(1, 'seconds')
                .toISOString()
        }),
        [startDate, endDate]
    );

    const defaultCustomer = useMemo(() => {
        if (customerId) {
            return customerData ?? null;
        }
        return null;
    }, [customerData, customerId]);

    const defaultEmployee = useMemo(() => employees.find((e) => e.id === employeeId) || null, [employees, employeeId]);
    const initialData = useMemo(
        () => ({
            status: AppointmentStatuses.Active,
            employee: defaultEmployee,
            location,
            services: [],
            customer: defaultCustomer,
            start_at: start,
            end_at: end,
            type: AppointmentType.Appointment,
            images: [],
            payments: null,
            is_notifications_enabled: true,
            note: '',
            private_note: '',
            deposit_request: null
        }),

        [location, defaultEmployee, defaultCustomer, start, end]
    );

    return (
        <CBModal
            fullScreen={matchSm}
            open={isOpen}
            onClose={onClose}
            title="Создать запись"
            styleOverrides={{
                '& .MuiDialogActions-root': {
                    borderTop: `1px solid ${theme.palette.grey[300]}`,
                    '& .DialogCancelBtn': { order: -2 },
                    '& .DialogSpecialContent': { order: -1, marginRight: 0, marginLeft: 'auto' }
                },
                '& .MuiDialogContent-root': { py: '20px !important', px: '0 !important', height: '900px' }
            }}
            okButtonText="Сохранить"
            okButtonFormId={appointmentDetailsFormId}
            okButtonDisabled={appointmentDetailsSaveButtonDisabled || depositRequest || customerLoading}
            container={container}
        >
            {customerLoading ? (
                <Box sx={{ p: 2, minWidth: '600px', maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <AppointmentForm
                    data={initialData}
                    callback={onClose}
                    isDepositRequested={depositRequest}
                    handleCancelDepositRequest={() => setDepositRequest(false)}
                    isNew
                />
            )}
        </CBModal>
    );
};

export default NewAppointmentModal;

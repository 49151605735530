// import { useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Box, Theme, useMediaQuery, Typography } from '@material-ui/core';

// project imports
// import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import WidgetLink from './WidgetLink';

// assets
import { IconMenu2 } from '@tabler/icons';
import SupportWidget from './SupportWidget';
import SearchFilter from '../../../ui-component/search-filter/SearchFilter';
import MobileCreateButton from './MobileCreateButton';
// import CopyVideoLink from '../../../ui-component/copy-video-link/CopyVideoLink';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import HeaderNavigation from '../../../ui-component/header-navigation';
import useNavigationMode from '../../use-navigation-mode';
import { Stack } from '@mui/material';
import OrganizationSwitcher from '../OrganizationSwitcher';
import LocationSwitcher from '../LocationSwitcher';
import NotificationSection from './NotificationSection';
import useAuth from '../../../hooks/useAuth';
import CommonHeaderBtn from '../../../ui-component/CommonHeaderBtn';
import CreateButtonFab from '../../../ui-component/CreateButtonFab';
import MicIcon from '@mui/icons-material/Mic';
import { openVoiceRecording } from '../../../store/slices/calendarSlice';
import UpdatedStyleWrapper from '../../../ui-component/updated-style-wrapper';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    boxContainer: {
        display: 'flex',
        width: 'auto',
        flexGrow: 0,
        flexShrink: 0
    }
}));

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
    handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const navMode = useNavigationMode();
    const bottomMenuVisible = useMediaQuery('(max-width:768px)');
    const { showSearch } = useAppSelector((state) => state.calendarFilter);
    const classes = useStyles();
    return (
        <>
            {/* logo & toggler button */}
            <div className={classes.boxContainer}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, mr: 1 }}>
                    <Typography variant="h2" fontSize="16px" textAlign="center" component="div">
                        ЗаписьПро
                    </Typography>
                </Box>
                {navMode === 'sidebar' ? (
                    <CommonHeaderBtn onClick={handleLeftDrawerToggle}>
                        <IconMenu2 stroke={1.5} />
                    </CommonHeaderBtn>
                ) : null}
                {/* <Stack direction="row" sx={{ alignItems: 'center', marginLeft: 4, flexGrow: 1, justifyContent: 'flex-end' }} spacing={2}>
                    {matchSm ? <WidgetLink /> : null}
                </Stack> */}
            </div>

            <Stack direction="row" sx={{ alignItems: 'center', marginLeft: 2, flexGrow: 1, justifyContent: 'flex-end' }} spacing={1}>
                <Box id="calendarFilterButton" sx={{ '&:empty': { display: 'none' } }} />
                <Box id="calendarModeSelector" sx={{ '&:empty': { display: 'none' } }} />
                <Box id="calendarAddButton" sx={{ '&:empty': { display: 'none' } }} />
                <MobileCreateButton />

                {showSearch && matchSm ? (
                    <CreateButtonFab color="secondary" onClick={() => dispatch(openVoiceRecording())}>
                        <MicIcon fontSize="small" />
                    </CreateButtonFab>
                ) : null}
                {showSearch && !bottomMenuVisible ? (
                    <Box flexGrow={3} marginRight="auto" sx={{ maxWidth: '500px' }}>
                        <SearchFilter />
                    </Box>
                ) : null}

                {navMode === 'header' ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 0,
                            justifyContent: 'center',
                            mr: 'auto !important',
                            ml: !showSearch ? 'auto !important' : undefined
                        }}
                    >
                        <HeaderNavigation />
                    </Box>
                ) : null}

                {navMode === 'header' ? (
                    <>
                        <WidgetLink />
                        {/* <CopyVideoLink /> */}
                        <SupportWidget />
                        <UpdatedStyleWrapper>
                            <LocationSwitcher />
                        </UpdatedStyleWrapper>
                        {user && !matchSm ? <OrganizationSwitcher /> : null}
                    </>
                ) : null}
                {user && !bottomMenuVisible ? <NotificationSection /> : null}
                <ProfileSection isMobile={matchSm} bottomMenuVisible={bottomMenuVisible} />
            </Stack>
        </>
    );
};

export default Header;

import { NavItemType, OverrideIcon } from 'types';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';

import {
    calendarItem,
    couponsItem,
    customerItem,
    employeeItem,
    // formBuilderItem,
    insightsItem,
    locationItem,
    organizationItem,
    // reportsItem,
    serviceItem,
    // settingsItem,
    // widgetItem,
    productItem,
    // siteBuilderItem,
    profileCardItem,
    notificationsSettingsItem,
    serviceCategoriesItem
} from './allSettings';

// ==============================|| MENU ITEMS ||============================== //

export interface MenuProps {
    id: string;
    title?: React.ReactNode | string;
    type: string;
    icon?: OverrideIcon;
    children: {
        id: string;
        role_subject: string;
        title: React.ReactNode | string;
        type: string;
        url: string;
        icon: OverrideIcon;
        breadcrumbs: boolean;
    }[];
}

export type NavItemWithChildren = NavItemType & { children: MenuProps['children'] };

const index: MenuProps = {
    id: 'index',
    type: 'group',
    children: [calendarItem]
};

const tools: MenuProps = {
    id: 'tools',
    title: 'Инструменты',
    type: 'group',
    icon: BuildOutlinedIcon,
    children: [profileCardItem, customerItem, /* reportsItem, */ couponsItem, insightsItem]
};

const settings: MenuProps = {
    id: 'settings',
    title: 'Настройки',
    type: 'group',
    icon: WidgetsOutlinedIcon,
    children: [
        /* settingsItem, */ employeeItem,
        serviceItem,
        serviceCategoriesItem,
        organizationItem,
        locationItem,
        notificationsSettingsItem,
        /* widgetItem, formBuilderItem, */ productItem
    ]
};

const menuItems: { items: Array<NavItemWithChildren> } = {
    items: [index, tools, settings]
};

export default menuItems;

import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../ui-component/Loadable';
import { lazy } from 'react';
import NavMotion from '../layout/NavMotion';
import WidgetError from '../views/scheduling-widget/widget-wizard/WidgetError';
import config from '../config';
import EmbeddedWidget from '../views/scheduling-widget/embedded/EmbeddedWidget';
import Widget from '../views/scheduling-widget/Widget';

const AppointmentsWidget = Loadable(lazy(() => import('../views/customer-appointments-widget/AppointmentsWidget')));
const DepositRequestWidget = Loadable(lazy(() => import('../views/deposit-request-widget/DepositRequestWidget')));
const InviteAppointmentWidget = Loadable(lazy(() => import('../views/invite-widget/index')));
const PublicSelfCheckout = Loadable(lazy(() => import('../views/public-self-checkout/index')));
const AppointmentCallScreen = Loadable(lazy(() => import('../views/calls/AppointmentCall')));
const EmployeeCallScreen = Loadable(lazy(() => import('../views/calls/EmployeeCall')));
const EmployeeWidget = Loadable(lazy(() => import('../views/provider-widget/index')));
const WaitlistRescheduleWidget = Loadable(lazy(() => import('../views/waitlist-reschedule-widget')));
const AppointmentConfirmation = Loadable(lazy(() => import('../views/appointment-confirmation-view')));

const PublicRoutes = [
    {
        path: 'cal',
        element: <MinimalLayout />,
        children: [
            {
                path: config.publicPath,
                element: <WidgetError />
            },
            {
                path: `${config.publicPath}/embed/provider/:providerName/:providerId/*`,
                element: <EmployeeWidget />
            },
            {
                path: `${config.publicPath}:company_slug/deposit-request/:appointment_uuid`,
                element: <DepositRequestWidget />
            },
            {
                path: `${config.publicPath}:company_slug/invite-to-schedule/:appointment_invite_uuid`,
                element: <InviteAppointmentWidget />
            },
            {
                path: `${config.publicPath}:company_slug/self-checkout/:appointment_uuid/*`,
                element: <PublicSelfCheckout />
            },
            {
                path: `${config.publicPath}:company_slug/*`,
                element: (
                    <NavMotion>
                        <Widget />
                    </NavMotion>
                )
            },
            {
                path: `${config.publicPath}/embed/:company_slug/*`,
                element: <EmbeddedWidget />
            },
            {
                path: `${config.publicPath}:company_slug/booking/:appointment_uuid`,
                element: (
                    <NavMotion>
                        <AppointmentsWidget />
                    </NavMotion>
                )
            },
            {
                path: `${config.publicPath}:company_slug/waitlist/:appointment_uuid`,
                element: <WaitlistRescheduleWidget />
            },
            {
                path: `${config.publicPath}:company_slug/cbvc/:appointment_uuid`,
                element: <AppointmentCallScreen />
            },
            {
                path: `${config.publicPath}:company_slug/:employee_slug/cbvc`,
                element: <EmployeeCallScreen />
            },
            {
                path: `${config.publicPath}:company_slug/confirm/:appointment_uuid`,
                element: <AppointmentConfirmation />
            }
        ]
    }
];

export default PublicRoutes;

import { Box, Chip, Link } from '@mui/material';
import { ForwardedRef, HTMLAttributes, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface ISearchPaperProps {
    count: number;
    query: string;
    listProps: HTMLAttributes<HTMLElement>;
    innerRef: ForwardedRef<HTMLElement>;
    reversed?: boolean;
    onSeeAllCb?: () => void;
}

const SearchListBox = ({ count, query, listProps, innerRef, reversed, onSeeAllCb }: ISearchPaperProps) => {
    const MAX_RESULTS = 10;
    const { children, ...rest } = listProps;

    const showFooter = useMemo(() => count > MAX_RESULTS, [count]);

    return (
        <Box
            {...rest}
            component="ul"
            ref={innerRef}
            sx={{
                '&.MuiAutocomplete-listbox': {
                    padding: 0,
                    paddingTop: reversed && showFooter ? '38px' : 0,
                    display: 'flex',
                    flexDirection: reversed ? 'column-reverse' : 'column'
                },
                '& .MuiListSubheader-root': {
                    fontWeight: 500,
                    color: 'rgba(0, 0, 0, 0.84)',
                    lineHeight: '30px',
                    background: '#fafafb',
                    borderTop: '1px solid #ebedf0',
                    borderBottom: '1px solid #ebedf0',
                    top: 0
                }
            }}
        >
            {children}
            {showFooter ? (
                <Box
                    component="li"
                    className="MuiListSubheader-root"
                    paddingX={2}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minHeight: '38px',
                        position: reversed ? 'absolute' : 'sticky',
                        bottom: reversed ? 'initial' : 0,
                        borderBottom: reversed ? 'none !important' : undefined,
                        zIndex: 10,
                        width: reversed ? '100%' : undefined
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box mr={0.5}>Найдено</Box>
                        <Chip
                            label={count}
                            size="small"
                            variant="outlined"
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                color: 'rgba(0, 0, 0, 0.84)',
                                minWidth: '28px',
                                border: '1px solid #EDE7F6',
                                background: 'rgba(237, 231, 246, 0.40)',
                                height: 'auto',

                                '& .MuiChip-label': {
                                    paddingX: 0.5,
                                    fontSize: '12px',
                                    lineHeight: '15px'
                                }
                            }}
                        />
                    </Box>
                    <Link to={`/search?query=${query}`} onClick={onSeeAllCb} underline="none" component={RouterLink}>
                        Посмотреть все
                    </Link>
                </Box>
            ) : null}
        </Box>
    );
};

export default SearchListBox;

import EventNoteOutlined from '@mui/icons-material/EventNoteOutlined';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import WorkOutline from '@mui/icons-material/WorkOutline';
import AssignmentIndOutlined from '@mui/icons-material/AssignmentIndOutlined';
import PeopleAltOutlined from '@mui/icons-material/PeopleAltOutlined';
import CouponIcon from '../ui-component/icons/CouponsIcon';
import MeetingRoomOutlined from '@mui/icons-material/MeetingRoomOutlined';
import FeedOutlined from '@mui/icons-material/FeedOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Settings from '@mui/icons-material/Settings';
import FactCheck from '@mui/icons-material/FactCheck';
import Receipt from '@mui/icons-material/Receipt';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import BuildIcon from '@mui/icons-material/Build';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SmsIcon from '@mui/icons-material/Sms';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

export const calendarItem = {
    id: 'calendar',
    role_subject: 'appointment',
    title: 'Календарь',
    type: 'item',
    url: '/calendar',
    icon: EventNoteOutlined,
    breadcrumbs: false
};

export const locationItem = {
    id: 'location',
    role_subject: 'location',
    title: 'Локации',
    type: 'item',
    url: '/location',
    icon: LocationOnOutlined,
    breadcrumbs: false
};

export const serviceItem = {
    id: 'service',
    role_subject: 'service',
    title: 'Услуги',
    type: 'item',
    url: '/service',
    icon: WorkOutline,
    breadcrumbs: false
};

export const serviceCategoriesItem = {
    id: 'service_categories',
    role_subject: 'service',
    role_action: 'update',
    title: 'Категории услуг',
    type: 'item',
    url: '/service-categories',
    icon: CategoryOutlinedIcon,
    breadcrumbs: false
};

export const productItem = {
    id: 'products',
    role_subject: 'product',
    title: 'Продукты',
    type: 'item',
    url: '/products',
    icon: LocalMallOutlinedIcon,
    breadcrumbs: false
};

export const employeeItem = {
    id: 'employee',
    role_subject: 'employee',
    title: 'Персонал',
    type: 'item',
    url: '/employee',
    icon: AssignmentIndOutlined,
    breadcrumbs: false
};

export const customerItem = {
    id: 'customer',
    role_subject: 'customer',
    title: 'Клиенты',
    type: 'item',
    url: '/customer',
    icon: PeopleAltOutlined,
    breadcrumbs: false
};

export const couponsItem = {
    id: 'coupons',
    role_subject: 'company',
    title: 'Купоны',
    type: 'item',
    url: '/coupons',
    icon: CouponIcon,
    breadcrumbs: false
};

export const organizationItem = {
    id: 'organization',
    role_subject: 'company',
    title: 'Компания',
    type: 'item',
    url: '/organization',
    icon: MeetingRoomOutlined,
    breadcrumbs: false
};
export const reportsItem = {
    id: 'reports',
    role_subject: 'company',
    title: 'Отчеты',
    type: 'item',
    url: '/reports',
    icon: FeedOutlined,
    breadcrumbs: false
};
export const insightsItem = {
    id: 'insights',
    role_subject: 'company',
    title: 'Статистика',
    type: 'item',
    url: '/insights',
    icon: DashboardIcon,
    breadcrumbs: false
};
export const settingsItem = {
    id: 'settings',
    role_subject: 'company',
    title: 'Общие',
    type: 'item',
    url: '/settings',
    icon: Settings,
    breadcrumbs: false
};
export const widgetItem = {
    id: 'widget-settings',
    role_subject: 'company',
    title: 'Виджет',
    type: 'item',
    url: '/widget-settings',
    icon: FactCheck,
    breadcrumbs: false
};
export const formBuilderItem = {
    id: 'form-builder',
    role_subject: 'company',
    title: 'Конструктор форм',
    type: 'item',
    url: '/forms',
    icon: Receipt,
    breadcrumbs: false
};

export const siteBuilderItem = {
    id: 'site-builder',
    role_subject: 'company',
    title: 'Веб страница',
    type: 'item',
    url: '/site-builder',
    icon: BuildIcon,
    breadcrumbs: false
};

export const profileCardItem = {
    id: 'profile-card',
    role_subject: 'company',
    title: 'Онлайн-запись',
    type: 'item',
    url: '/profile-card',
    icon: AccountBoxIcon,
    breadcrumbs: false
};

export const notificationsSettingsItem = {
    id: 'sms-settings',
    role_subject: 'company',
    title: 'Настройки уведомлений',
    type: 'item',
    url: '/sms-management',
    icon: SmsIcon,
    breadcrumbs: false
};

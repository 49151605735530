import React, { useCallback, useState /* useEffect, */ } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { IconLogout, IconSettings /* , IconCurrencyDollar */ } from '@tabler/icons';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import {
    Avatar,
    CardContent,
    Chip,
    Divider,
    Grid,
    // Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    Typography
} from '@material-ui/core';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import SmsIcon from '@mui/icons-material/Sms';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
// import FileCopyIcon from '@mui/icons-material/FileCopy';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import useAuth from 'hooks/useAuth';
import { DefaultRootStateProps } from 'types';
import preferredImageSize from '../../../../utils/preferred-image-size';
// import { SnackBarTypes } from '../../../../store/snackbarReducer';
// import useShowSnackbar from '../../../../hooks/useShowSnackbar';

// assets
import OrganizationSwitcher from '../../OrganizationSwitcher';
import { colors } from '../../../../store/constant';
import { UserRole, UserRoleLabel } from '../../../../models/IEmployee';
// import accountService from '../../../../services/AccountService';
// import { Box, Switch, Tooltip } from '@mui/material';
// import QrCodeDialogToggle from '../../../../ui-component/QrCodeDialogToggle';
import QRCode from 'react-qr-code';
import CBModal from '../../../../ui-component/cb-modal/CBModal';

// style const
const useStyles = makeStyles((theme: Theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '300px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    headerAvatar: {
        cursor: 'pointer',
        ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important',
        [theme.breakpoints.down('md')]: {
            width: '26px',
            height: '26px',
            margin: '3px 0 3px 8px !important'
        }
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.secondary.main,
            background: `${theme.palette.secondary.main}!important`,
            color: theme.palette.secondary.light,
            '& svg': {
                stroke: theme.palette.secondary.light
            }
        },
        [theme.breakpoints.down('md')]: {
            height: '36px'
        }
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px'
    },
    listItem: {
        marginTop: '5px'
    },
    cardContent: {
        padding: '16px !important'
    },
    card: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
        marginBottom: '16px',
        marginTop: '16px'
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    flex: {
        display: 'flex'
    },
    name: {
        fontWeight: 400
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden'
    },
    badgeWarning: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.warning.dark,
        color: '#fff'
    },
    logo: {
        ...theme.typography.mediumAvatar
    }
}));

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = ({ isMobile, bottomMenuVisible }: { isMobile: boolean; bottomMenuVisible: boolean }) => {
    // const [profileCardEnabled, setProfileCardEnabled] = useState<boolean | null>(null);
    const [showQr, setShowQr] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const [selectedIndex] = React.useState(1);
    // const { showSnackbar } = useShowSnackbar();
    const { logout, user } = useAuth();
    // const { data: cardData, isLoading: cardDataLoading } = accountService.useGetAccountCardDataQuery(null);
    // const [save, { isLoading: cardDataSaving }] = accountService.useUpdateAccountCardDataMutation();

    const isWebView = navigator.userAgent.includes('SwiftWebView');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
        location.state = null;
    };

    const handleOpenSettings = () => {
        navigate('/profile');
    };

    /* const host = window.location.origin;
    const providerLink = useMemo(() => {
        if (user) {
            const { firstname, lastname, employee } = user;
            return `${host}/cal/provider/${firstname}-${lastname}/${employee.id}`;
        }

        return null;
    }, [host, user]);

    const handleCopy = useCallback(() => {
        if (providerLink) {
            navigator.clipboard.writeText(providerLink).then(() => {
                showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Link copied to clipboard!' });
            });
        }
    }, [providerLink, showSnackbar]); */

    /* const handleOpenLinkTree = useCallback(() => {
        if (!(cardDataLoading || cardDataSaving)) {
            navigate('/profile-card');
        }
    }, [cardDataLoading, cardDataSaving, navigate]);

    const handleToggleCard = useCallback(
        (enabled: boolean) => {
            setProfileCardEnabled(enabled);
            save({ ...cardData, slug: cardData?.slug ?? '', enabled })
                .unwrap()
                .then(() => {
                    if (!enabled) {
                        navigate('/calendar');
                    }
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.data || err.message || JSON.stringify(err)
                    });
                    setProfileCardEnabled((prev) => !prev);
                });
        },
        [cardData, navigate, save, showSnackbar]
    );
 */
    // const cardDataEnabled = Boolean(cardData?.enabled);

    const roleName = user?.employee.role.name;

    // Watch for changes
    /* useEffect(() => {
        setProfileCardEnabled(cardDataEnabled);
    }, [cardDataEnabled]);
 */
    return (
        <>
            {user && (
                <>
                    <Chip
                        classes={{ label: classes.profileLabel }}
                        className={classes.profileChip}
                        size="small"
                        icon={
                            <Avatar
                                src={preferredImageSize(user.avatar, 'small')}
                                className={classes.headerAvatar}
                                aria-controls={anchorEl ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                color="inherit"
                                // src={getLogo(company.logo)}
                                sx={{
                                    backgroundColor: user.employee.background_color
                                        ? `#${user.employee.background_color}`
                                        : colors.blue.value
                                }}
                            >
                                <Typography
                                    fontSize="inherit"
                                    sx={{
                                        color: theme.palette.getContrastText(
                                            user.employee.background_color ? `#${user.employee.background_color}` : colors.blue.value
                                        )
                                    }}
                                >
                                    {user?.title?.charAt(0).toUpperCase()}
                                </Typography>
                            </Avatar>
                        }
                        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.secondary.main} />}
                        variant="outlined"
                        aria-controls={anchorEl ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={openMenu}
                        color="secondary"
                    />
                    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenu}>
                        <MainCard border={false} elevation={16} content={false} sx={{ boxShadow: 'none !important' }}>
                            <CardContent className={classes.cardContent}>
                                <Grid container direction="column" spacing={0} px={2}>
                                    <Grid item className={classes.flex}>
                                        <Typography variant="h4" className={classes.name} maxWidth={400}>
                                            {user?.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                                            {UserRoleLabel[user.employee.role.name]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {/* {isMobile && (
                                    <>
                                        <OrganizationSwitcher sx={{ m: '10px 0', width: '100%' }} />
                                        <Divider />
                                    </>
                                )} */}
                                <List component="nav" className={classes.navContainer}>
                                    <ListItemButton
                                        className={classes.listItem}
                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                        selected={selectedIndex === 4}
                                        onClick={handleOpenSettings}
                                    >
                                        <ListItemIcon>
                                            <ManageAccounts />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography variant="body2">Профиль</Typography>} />
                                    </ListItemButton>

                                    {/* {user.site_url && roleName == 'owner' && (
                                        <Link onClick={handleOpenLinkTree} underline="none">
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <AccountBoxIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            component="div"
                                                            variant="body2"
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            Онлайн визитка
                                                            <Box>
                                                                <Tooltip title="Включить онлайн визитку">
                                                                    <span>
                                                                        <Switch
                                                                            disabled={cardDataLoading || cardDataSaving}
                                                                            onClick={(e) => e.stopPropagation()}
                                                                            checked={Boolean(profileCardEnabled)}
                                                                            onChange={(_, checked) => handleToggleCard(checked)}
                                                                            color="secondary"
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                                <QrCodeDialogToggle
                                                                    color="secondary"
                                                                    sx={{
                                                                        padding: 0,
                                                                        width: '30px',
                                                                        height: '30px',
                                                                        ml: 1
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setShowQr(true);
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </Link>
                                    )} */}

                                    {roleName === UserRole.Owner && !isWebView && (
                                        <ListItemButton
                                            className={classes.listItem}
                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                            selected={selectedIndex === 4}
                                            onClick={() => {
                                                navigate('/sms-management');
                                                closeMenu();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <SmsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography variant="body2">Настройки уведомлений</Typography>} />
                                        </ListItemButton>
                                    )}

                                    {roleName === UserRole.Owner && !isWebView && (
                                        <ListItemButton
                                            className={classes.listItem}
                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                            selected={selectedIndex === 4}
                                            onClick={() => {
                                                navigate('/subscription-management');
                                                closeMenu();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <UnsubscribeIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography variant="body2">Управление подпиской</Typography>} />
                                        </ListItemButton>
                                    )}

                                    <Divider sx={{ my: 1 }} />
                                    <ListItemButton
                                        className={classes.listItem}
                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                        selected={selectedIndex === 4}
                                        onClick={handleLogout}
                                    >
                                        <ListItemIcon>
                                            <IconLogout stroke={1.5} size="1.3rem" />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography variant="body2">Выйти</Typography>} />
                                    </ListItemButton>
                                </List>
                            </CardContent>
                        </MainCard>
                    </Menu>
                    {user.site_url ? (
                        <CBModal open={showQr} onClose={() => setShowQr(false)} title="Онлайн визитка">
                            <QRCode value={user.site_url} />
                        </CBModal>
                    ) : null}
                </>
            )}
        </>
    );
};

export default ProfileSection;
